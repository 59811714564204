export default {
  name: "Nama",
  age: "Umur",
  address: "Alamat",
  contactNo: "Contact No",
  lang: "id",
  menu1: "Beranda",
  menu2: "Tentang Kami",
  menu3: "Layanan",
  menu4: "Karir",
  menu5: "Kontak",
  home: {
    section1: "Tentang Kami",
    description1:
      "Mahaguna Komando Indonesia adalah perusahaan berlisensi yang bergerak di bidang penyedia jasa keamanan.",
    description2:
      "Berpusat di Kalimantan Timur sejak awal operasinya pada 1 Oktober 2018, Mahaguna Komando Indonesia memperluas cakupan wilayah kegiatannya di Jakarta.",
    section2: "Layanan Kami",
    service1: "Jasa Keamanan",
    service2: "Drone Surveillance",
    service3: "Jasa Pengawalan Tongkang Batubara",
    section3: "Klient Kami",
    heroDescription1: "Melayani dengan profesional,",
    heroDescription2: "Melindungi dengan komitmen.",
  },
  aboutUs: {
    linkCover:
      "https://drive.usercontent.google.com/download?id=15a5bfSzTp7HV7qHvKSLI3pC5OFwkQGxm&export=download&authuser=0&confirm=t&uuid=b5e2b14a-f7d3-4006-a025-8004974a8280&at=AC2mKKSxvmtJazzMleJyxNC2pc1I:1690457380033",
    downloadButton: "Unduh Profil Perusahaan",
    section1: "Profil Perusahaan",
    subSection1: "Tentang Mahaguna Komando Indonesia:",
    description1:
      "Mahaguna Komando Indonesia adalah perusahaan berlisensi yang bergerak di bidang penyedia jasa keamanan. Berpusat di Kalimantan Timur sejak awal operasinya pada 1 Oktober 2018, Mahaguna Komando Indonesia memperluas cakupan wilayah kegiatannya di Jakarta.",
    description2:
      "<b>Kami memiliki tiga segmen layanan terintegrasi</b>: jasa pengamanan, drone surveillance, dan jasa pengawalan kapal tongkang. Kinerja yang profesional dan peralatan yang lengkap mampu menciptakan ekosistem perlindungan yang adaptif dan efisien.",
    description3:
      "Konsistensi selama enam tahun di bidang keamanan, Mahaguna Komando Indonesia fokus menjaga kualitas pelayanan melalui sistem yang efisien dan terpercaya. Kami mengutamakan kepuasan pelanggan dengan memberikan solusi yang sesuai dengan kebutuhan.",
    section2: "Nilai Perusahaan",
    titlevalue1: "Professional",
    descriptionvalue1:
      "Kualitas yang memiliki standar tinggi memberikan jaminan pelayanan bermutu. Mahaguna Komando Indonesia mempersiapkan personelnya dengan pelatihan dan peralatan yang lengkap.",
    titlevalue2: "Fast & Responsive",
    descriptionvalue2:
      "Melalui perencanaan efektif, Mahaguna Komando Indonesia menjalankan operasi keamanan dengan tepat dan cermat, serta menciptakan strategi keamanan yang terkoordinasi.",
    titlevalue3: "Safe & Secure",
    descriptionvalue3:
      "Dengan mengedepankan integritas dan kredibilitas dalam pelayanan, Mahaguna Komando Indonesia melakukan operasi dengan penuh tanggung jawab.",
    section3: "Visi & Misi",
    subtitle1: "Visi :",
    section3description1:
      "Mahaguna Komando Indonesia memiliki visi untuk menjadi perusahaan profesional penyedia jasa keamanan yang terpercaya dan terkemuka di Indonesia.",
    subtitle2: "Mission :",
    section3description2:
      "Fokus pada efisiensi sistem kerja yang sesuai dengan kebutuhan pelanggan, Mahaguna Komando Indonesia selalu meningkatkan kompetensi dan profesionalisme para karyawan untuk menjaga kualitas pelayanan.",
  },
  services: {
    section1: "Layanan Kami",
    subSection1: "Jasa Keamanan",
    descriptionSection1:
      "Dengan kapasitas sumber daya manusia yang tinggi, Mahaguna Komando Indonesia menjamin keamanan aset anda yang dipercayakan kepada kami.",
    subSection2: "Drone Surveillance",
    descriptionSection2:
      "Teknologi drone (pesawat nirawak) merupakan kemajuan yang pesat. Dengan mesin canggih tersebut, Mahaguna Komando Indonesia menyediakan jasa survei lahan untuk kebutuhan anda.",
    subSection3: "Jasa Pengawalan Tongkang Batubara",
    descriptionSection3:
      "Mahaguna Komando Indonesia menyediakan jasa pengawalan distribusi jalur laut, khususnya kapal tongkang batubara. Kami memastikan keamanan aset anda dari awal sampai tujuan.",
  },
  career: {
    section1: "Berkarir Bersama Kami",
    data1: "Segera Hadir",
    data2: "Segera Hadir",
    data3: "Segera Hadir",
    buttonApply: "Ajukan Sekarang",
  },
  contact: {
    data1: "Alamat Perusahaan",
    data2: "No. Telepon",
  },
  footer: {
    navigation1: "Informasi",
    navigation2: "Email",
  },
};
