import React from 'react';
import Header from './header/header'
import HomeContent from './content-home/content-home';
import Footer from './footer/footer';
import { Helmet } from 'react-helmet';

class Index1 extends React.Component {
    render() {
      return (
        <React.Fragment>
          <Helmet>
            <title>Mahaguna - Security Services</title>
          </Helmet>
          <Header />
          <HomeContent />
          <Footer />
        </React.Fragment>
      );
    }
  }
  
  export default Index1;