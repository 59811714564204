import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Client extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="containerAboutUs2">
          <div className="containerTitle col text-center">
            <h2 className="aboutUs">Klien Kami</h2>
            <div className="hrLayananKami text-center" />
          </div>
          <div className="imageBannerClient">
            <img
              className="imageRealClient"
              src="/assets/images/banner-2.webp"
            />
          </div>
          <div className="containerPeople">
            <img className="wawaPeople" src="/assets/images/iconPeople1.png" />
            <div className="colData">
              <h2 className="text-CLient">PT. Multi Harapan Utama</h2>
              <div className="hrLineClient" />
              <h2 className="desc-CLient">Loa Kulu, Kutai Kartanegara</h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Client;
