import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMailBulk,
  faMessage,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "../../contexts/translation";

const Footer = () => {
  const translation = useTranslation();
  return (
    <React.Fragment>
      <footer class="footer">
        <div class="later-filter"></div>
        <div class="content">
          <div className="footer-left">
            <img
              src="/assets/images/logoHeader.png"
              className="imageLogoFooter"
            />
          </div>
          <div className="footer-right">
            <div className="footer-content">
              <nav className="navbars">
                <ul className="nav-menu1">
                  <p className="quickLink">Quick Link</p>
                  <li className="nav-items">
                    <a href="/">{translation.menu1}</a>
                  </li>
                  <li className="nav-items">
                    <a href="/TentangKami">{translation.menu2}</a>
                  </li>
                  <li className="nav-items">
                    <a href="/Layanan">{translation.menu3}</a>
                  </li>
                  <li className="nav-items">
                    <a href="/Career">{translation.menu4}</a>
                  </li>
                  <li className="nav-items">
                    <a href="/Contact">{translation.menu5}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="footer-content">
              <nav className="navbars">
                <ul className="nav-menu1">
                  <p className="quickLink">{translation.footer.navigation1}</p>
                  <p className="paragraphInformasi">
                    <b>Commodity Square Lt. 1</b> <br />
                    Jl. Gatot Subroto No.35-36, RT.6/RW.3, Kuningan, Kuningan
                    Tim., Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah
                    Khusus Ibukota Jakarta 12950
                    <br /> <br />
                    <p className="quickLink">
                      {translation.footer.navigation2}
                    </p>
                    <a
                      href="mailto:info@mkisecurity.com"
                      style={{ color: "#FFFFFF", textDecoration: "none" }}
                    >
                      <p>info@mkisecurity.com</p>
                    </a>
                  </p>
                </ul>
              </nav>
            </div>
            {/* <div className="footer-content">
              <nav className="navbars">
                <ul className="nav-menu1">
                  <p className="quickLink">{translation.footer.navigation2}</p>
                  <div className="wawa">
                    <FontAwesomeIcon
                      style={{ marginRight: "10px" }}
                      icon={faMailBulk}
                    />
                    <a
                      href="mailto:richard.hee@mkisecurity.com"
                      style={{ color: "#FFFFFF", textDecoration: "none" }}
                    >
                      <p>richard.hee@mkisecurity.com</p>
                    </a>
                  </div>
                  <div className="wawa">
                    <FontAwesomeIcon
                      style={{ marginRight: "10px" }}
                      icon={faPhone}
                    />
                    <a
                      href="tel:081210013336"
                      style={{ color: "#FFFFFF", textDecoration: "none" }}
                    >
                      <p>+62 812 1001 333 6</p>
                    </a>
                  </div>
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="footerBrown">
        © 2023 Mahaguna Komando Indonesia. All rights reserved.
      </div>
    </React.Fragment>
  );
};

export default Footer;
