import React from "react";
import { useLanguageContext } from "./LanguageContext";

export default function LanguageHandler() {
  const { language, changeLanguage } = useLanguageContext();

  return (
    <div>
      <img
        style={{  width: "26px", height: "26px" }}
        src={
          language == "id" ? "/assets/images/ind.jpg" : "/assets/images/eng.jpg"
        }
      />
    </div>
  );
}
