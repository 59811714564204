import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "../../contexts/translation";
import { useInView } from "react-intersection-observer";
import { useTransition, useSpring, animated, config } from "react-spring";

const Services = () => {
  const translation = useTranslation();

  const items = [
    <>
      <div className="card">
        <img
          src="/assets/images/card-1.webp"
          alt="Card Image"
          className="card-image"
        />
        <div className="olecontent">
          <div className="card-content">
            <div className="card-icon">
              <div className="redRoundImage">
                <img
                  className="iconRoundImage2"
                  src="/assets/images/cardIcon-1.png"
                />
              </div>
            </div>
            <div className="card-text">{translation.services.subSection1}</div>
          </div>
          <p
            className="whiteColor"
            style={{ marginTop: "1rem", color: "#FFFFFFF" }}
          >
            {translation.services.descriptionSection1}
          </p>
        </div>
      </div>
    </>,
    <>
      <div className="card">
        <img
          src="/assets/images/card-2.webp"
          alt="Card Image"
          className="card-image"
        />
        <div className="olecontent">
          <div className="card-content">
            <div className="card-icon">
              <div className="redRoundImage">
                <img
                  className="iconRoundImage2"
                  src="/assets/images/cardIcon-2.png"
                />
              </div>
            </div>
            <div className="card-text">{translation.services.subSection2}</div>
          </div>
          <p
            className="whiteColor"
            style={{ marginTop: "1rem", color: "#FFFFFFF" }}
          >
            {translation.services.descriptionSection2}
          </p>
        </div>
      </div>
    </>,
    <>
      <div className="card">
        <img
          src="/assets/images/card-3.webp"
          alt="Card Image"
          className="card-image"
        />
        <div className="olecontent">
          <div className="card-content">
            <div className="card-icon">
              <div className="redRoundImage">
                <img
                  className="iconRoundImage2"
                  src="/assets/images/cardIcon-3.png"
                />
              </div>
            </div>
            <div className="card-text">{translation.services.subSection3}</div>
          </div>
          <p
            className="whiteColor"
            style={{ marginTop: "1rem", color: "#FFFFFFF" }}
          >
            {translation.services.descriptionSection3}
          </p>
        </div>
      </div>
    </>,
  ];
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });

  const transitions = useTransition(items, {
    from: { opacity: 0, transform: "translateX(-20px)" },
    enter: inView ? { opacity: 1, transform: "translateX(0px)" } : null,
    config: config.slow, // Play with different configurations to adjust the animation feel
    trail: 300, // Set the delay between each item's animation
  });

  return (
    <React.Fragment>
      <div className="containerAboutUs2">
        <div className="containerLayananKami">
          <div className="containerTitle col text-center">
            <h2 className="aboutUs">{translation.services.section1}</h2>
            <div className="hrLayananKami text-center" />
          </div>

          <div className="card-container2" ref={ref}>
            {transitions((style, item) => (
              <animated.div style={style} key={item}>
                {item}
              </animated.div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
