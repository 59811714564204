import React, { useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faNavicon,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "../../contexts/translation";
import { useLanguageContext } from "../../contexts/LanguageContext";
import cmp_id from "./../../assets/videos/cmp_id.webm";
import cmp_en from "./../../assets/videos/cmp_en.webm";
import { useSpring, animated, config, useTransition } from "react-spring";
import { useInView } from "react-intersection-observer";

const HomeContent = () => {
  const translation = useTranslation();
  const { language, changeLanguage } = useLanguageContext();
  const handleClick = () => {
    window.location.assign("/Layanan");
  };
  const handleClickVideo = () => {
    window.open("https://www.youtube.com/watch?v=bRgL_Dry7uw", "_blank");
  };
  const NextArrow = ({ onClick }) => (
    <button
      className="next slick-arrow"
      style={{ right: "-23px" }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} style={{ color: "black" }} />
    </button>
  );

  const BackArrow = ({ onClick }) => (
    <button
      className="slick-arrow prev"
      style={{ left: "-28px" }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleLeft} style={{ color: "black" }} />
    </button>
  );
  const images = [
    "klien-8.png",
    "klien-1.png",
    "klien-2.png",
    "klien-3.png",
    "klien-4.png",
    "klien-5.png",
    "klien-6.png",
    "klien-7.png",
    "klien-9.png",
    "klien-10.png",
    "klien-11.png",
  ];
  var settingses = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <BackArrow />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    //  className: 'slick-slider-fade',
  };

  const items = [
    <>
      <div
        onClick={() => handleClick()}
        style={{ cursor: "pointer" }}
        className="card2"
      >
        <img
          src="/assets/images/card-1.webp"
          alt="Card Image"
          className="card-image"
        />
        <div className="card-content">
          <div className="card-icon">
            <div className="redRoundImage">
              <img
                className="iconImageRound"
                src="/assets/images/cardIcon-1.png"
              />
            </div>
          </div>
          <div className="card-text2">{translation.home.service1}</div>
        </div>
      </div>
    </>,
    <>
      <div
        onClick={() => handleClick()}
        style={{ cursor: "pointer" }}
        className="card2"
      >
        <img
          src="/assets/images/card-2.webp"
          alt="Card Image"
          className="card-image"
        />
        <div className="card-content">
          <div className="card-icon">
            <div className="redRoundImage">
              <img
                className="iconImageRound"
                src="/assets/images/cardIcon-2.png"
              />
            </div>
          </div>
          <div className="card-text2">{translation.home.service2}</div>
        </div>
      </div>
    </>,
    <>
      <div
        onClick={() => handleClick()}
        style={{ cursor: "pointer" }}
        className="card2"
      >
        <img
          src="/assets/images/card-3.webp"
          alt="Card Image"
          className="card-image"
        />
        <div className="card-content">
          <div className="card-icon">
            <div className="redRoundImage">
              <img
                className="iconImageRound"
                src="/assets/images/cardIcon-3.png"
              />
            </div>
          </div>
          <div className="card-text2">{translation.home.service3}</div>
        </div>
      </div>
    </>,
  ];
  const [fadeAnimationComplete, setFadeAnimationComplete] = useState(false);
  const [slideAnimationComplete, setSlideAnimationComplete] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.8, // Percentage of the element that must be visible to trigger the animation
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });

  const fadeAnimation = useSpring({
    opacity: inView ? 1 : 0,
    config: config.slow,
    onRest: () => setFadeAnimationComplete(true),
  });

  // const slideAnimation = useSpring({
  //   transform: inView2 ? "translateX(0)" : "translateX(-100%)",
  //   config: config.slow,
  // });

  const transitions = useTransition(items, {
    from: { opacity: 0, transform: "translateX(-20px)" },
    enter:
      inView2 && !inView ? { opacity: 1, transform: "translateX(0px)" } : null,
    config: config.slow, // Play with different configurations to adjust the animation feel
    trail: 300, // Set the delay between each item's animation
  });
  return (
    <React.Fragment>
      <div className="containerAboutUs">
        <a href="/TentangKami" id="oke" style={{ display: "none" }}></a>
        <animated.div style={{ ...fadeAnimation }}>
          <Row style={{ alignItems: "center" }}>
            <div className="imagesContent">
              <video
                controls
                poster="/assets/images/banner-2.webp"
                className="imagesBanner2"
                type="video/webm"
                src={language == "en" ? cmp_en : cmp_id}
                alt="Image 3"
              />
            </div>
            <div className="col marginTextImage" ref={ref}>
              <div>
                <h2 className="aboutUs">{translation.home.section1}</h2>
                <div className="hrLineaboutUs" />
                <p className="textAboutUs">{translation.home.description1}</p>
                <p className="textAboutUs">{translation.home.description2}</p>
              </div>

              <div
                onClick={() => {
                  document.getElementById("oke").click();
                }}
                style={{ cursor: "pointer" }}
                className="buttonSeeMore"
              >
                See More
              </div>
            </div>
          </Row>
        </animated.div>

        <div className="hrLinebetweenSection" />

        <animated.div style={{ ...transitions }}>
          <div className="containerLayananKami">
            <div className="containerTitle col text-center">
              <h2 className="aboutUs">{translation.home.section2}</h2>
              <div className="hrLayananKami text-center" />
            </div>
            <div className="card-container" ref={ref2}>
              {transitions((style, item) => (
                <animated.div style={style} key={item}>
                  {item}
                </animated.div>
              ))}
            </div>
          </div>
        </animated.div>
      </div>
      <div className="containerOurClient">
        <div className="containerTitle col text-center">
          <h2 className="ourClient">{translation.home.section3}</h2>
          <div className="hrKlienKami text-center" />
        </div>
        {
          // <div className="image-grid">
          <Slider {...settingses}>
            {images.map((image, index) => (
              // <div key={index} className="grid-item">
              //   <img style={index == 0 ? {width: '145px', height:'145px'}: {} } className="imageLogo" src={'/assets/images/' +image} alt={`Image ${index + 1}`} />
              // </div>

              <div key={index} className="grid-item212">
                <img src={"/assets/images/" + image} alt={image} />
              </div>
            ))}
          </Slider>
          // </div>
        }
      </div>
    </React.Fragment>
  );
};

export default HomeContent;
