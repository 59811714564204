import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import headerPage from "./pages/Index";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import Client from "./pages/Client";
import Contact from "./pages/Contact";
import "./App.css";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route exact path="/" component={headerPage} />
          <Route exact path="/TentangKami" component={AboutUs} />
          <Route exact path="/Layanan" component={Services} />
          <Route exact path="/Client" component={Client} />
          <Route exact path="/Career" component={Career} />
          <Route exact patch="/Contact" component={Contact} />
          {/* <Route path="/*" component={NoMatch} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
