import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faNavicon,
  faUserMinus,
  faPlay,
  faPause,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import HeaderMobilePage from "./headerMobilePage";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState, useEffect } from "react";
import LanguageHandler from "../../contexts/LanguageHandler";
import useTranslation from "../../contexts/translation";
import { useLanguageContext } from "../../contexts/LanguageContext";
import cmp_id from "./../../assets/videos/cmp_id.webm";
import cmp_en from "./../../assets/videos/cmp_en.webm";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, changeLanguage } = useLanguageContext();
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlayed, setIsPlayed] = useState(true);

  const handlePauseToggle = () => {
    if (videoRef.current.paused) {
      setIsPlayed(true);
      videoRef.current.play();
    } else {
      setIsPlayed(false);
      videoRef.current.pause();
    }
  };

  const handleMuteToggle = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const sidebar = document.querySelector(".sidebarxopen");
    console.log("wawa", sidebar);
    if (sidebar == null) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
      if (sidebar == null) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const NextArrow = ({ onClick }) => (
    <button className="next slick-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );

  const BackArrow = ({ onClick }) => (
    <button className="slick-arrow prev" onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );

  var settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    fade: true,
    arrows: true,
    initialSlide: 0,
    dots: false,
    draggable: true,
    nextArrow: <NextArrow />,
    prevArrow: <BackArrow />,
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          arrows: false,
        },
      },
    ],
    //  className: 'slick-slider-fade',
  };

  const translation = useTranslation();

  return (
    <React.Fragment>
      <div className="carousel-wrapper">
        <header className="header">
          <a href="/">
            <div className="logo">
              <img className="logoImage" src="/assets/images/logoHeader.png" />
            </div>
          </a>
          <nav className="navbar">
            <ul className="nav-menu">
              <li className="nav-item">
                <a href="/">{translation.menu1}</a>
              </li>
              <li className="nav-item">
                <a href="/TentangKami">{translation.menu2}</a>
              </li>
              <li className="nav-item">
                <a href="/Layanan">{translation.menu3}</a>
              </li>
              <li className="nav-item">
                <a href="/Career">{translation.menu4}</a>
              </li>
              <li className="nav-item">
                <a href="/Contact">{translation.menu5}</a>
              </li>
              <li>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    language == "id"
                      ? changeLanguage("en")
                      : changeLanguage("id");
                  }}
                  className="divLanguage"
                >
                  <LanguageHandler />
                  <span className="languageText">
                    {translation.lang == "en" ? "ENG" : "IND"}
                  </span>{" "}
                  &nbsp;
                </div>
              </li>
            </ul>
          </nav>
          <div
            onClick={handleToggle}
            className={isOpen ? "navbarMobileBlack" : "navbarMobileWhite"}
          >
            <FontAwesomeIcon
              icon={faNavicon}
              color={isOpen ? "black" : "white"}
            />
          </div>

          {isOpen ? <HeaderMobilePage /> : null}
        </header>
        {window.location.href.split("/")[3] === "" && (
          <div className="text-overlay">
            <h2 className="textBanner">
              {translation.home.heroDescription1}
              <br />
              {translation.home.heroDescription2}
            </h2>
          </div>
        )}
        <div className="layer-filter">
          {window.location.href.split("/")[3] === "TentangKami" ? (
            <div className="video-container">
              <div className="floating-buttons">
                <button onClick={handlePauseToggle}>
                  {!isPlayed ? (
                    <FontAwesomeIcon icon={faPlay} />
                  ) : (
                    <FontAwesomeIcon icon={faPause} />
                  )}
                </button>
                <button onClick={handleMuteToggle}>
                  {isMuted ? (
                    <FontAwesomeIcon icon={faVolumeMute} />
                  ) : (
                    <FontAwesomeIcon icon={faVolumeUp} />
                  )}
                </button>
              </div>
              <video
                ref={videoRef}
                className="imagesBanner"
                autoPlay
                loop
                type="video/webm"
                src={language == "en" ? cmp_en : cmp_id}
                alt="Image 3"
              />
            </div>
          ) : window.location.href.split("/")[3] !== "Contact" ? (
            <Slider {...settings}>
              <div>
                <img
                  className="imagesBanner"
                  src="/assets/images/banner-1.webp"
                  alt="Image 1"
                />
              </div>
              <div>
                <img
                  className="imagesBanner"
                  src="/assets/images/banner-2.webp"
                  alt="Image 2"
                />
              </div>
              <div>
                <img
                  className="imagesBanner"
                  src="/assets/images/banner-3.webp"
                  alt="Image 3"
                />
              </div>
              <div>
                <img
                  className="imagesBanner"
                  src="/assets/images/banner-5.webp"
                  alt="Image 5"
                />
              </div>
              <div>
                <img
                  className="imagesBanner"
                  src="/assets/images/banner-6.webp"
                  alt="Image 6"
                />
              </div>
            </Slider>
          ) : (
            <div>
              <img
                className="imagesBanner"
                src="/assets/images/banner-4.webp"
                alt="Image 3"
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
