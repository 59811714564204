import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import LanguageHandler from "../../contexts/LanguageHandler";
import "./IndexMobile.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { useLanguageContext } from "../../contexts/LanguageContext";
import useTranslation from "../../contexts/translation";

const HeaderMobilePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, changeLanguage } = useLanguageContext();
  const translation = useTranslation();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebarxopen`}>
      <div className="sidebar-togglex" onClick={() => toggleSidebar()}></div>
      <ul className="menu-listx">
        <li className="menu-list-itemx">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              language == "id" ? changeLanguage("en") : changeLanguage("id");
            }}
            className="divLanguageMobile"
          >
            <LanguageHandler />
            <span className="languageTextMobile">
              {translation.lang == "en" ? "ENG" : "IND"}
            </span>{" "}
            &nbsp;
          </div>
        </li>
        <li className="menu-list-itemx">
          <a href="/" className="menu-list-linkx">
            {translation.menu1}
          </a>
        </li>
        <li className="menu-list-itemx">
          <a href="/TentangKami" className="menu-list-linkx">
          {translation.menu2}
          </a>
        </li>
        <li className="menu-list-itemx">
          <a href="/Layanan" className="menu-list-linkx">
          {translation.menu3}
          </a>
        </li>
        <li className="menu-list-itemx">
          <a href="/Career" className="menu-list-linkx">
          {translation.menu4}
          </a>
        </li>
        <li className="menu-list-itemx">
          <a href="/Contact" className="menu-list-linkx">
          {translation.menu5}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default HeaderMobilePage;
