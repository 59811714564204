import React from 'react';
import Header from './header/header'
import Client from './Klien/Client';
import Footer from './footer/footer';
import { Helmet } from 'react-helmet';

class AboutUs extends React.Component {
    render() {
      return (
        <React.Fragment>
          <Helmet>
            <title>Mahaguna - Tentang Kami</title>
          </Helmet>
          <Header />
          <Client />
          <Footer />
        </React.Fragment>
      );
    }
  }
  
  export default AboutUs;