export default {
  name: "Name",
  age: "Age",
  address: "Address",
  contactNo: "Contact No",
  lang: "en",
  menu1: "Home",
  menu2: "About Us",
  menu3: "Services",
  menu4: "Career",
  menu5: "Contact",
  home: {
    section1: "About Us",
    description1:
      "Mahaguna Komando Indonesia is a licensed company engaged in providing security services based in East Kalimantan.",
    description2:
      "Since the beginning of our operations on October 1st, 2018, we had expanded our coverage area in Jakarta.",
    section2: "Our Services",
    service1: "Security Services",
    service2: "Drone Surveillance",
    service3: "Barge Escort Services",
    section3: "Our Clients",
    heroDescription1: "Serve with professionalism,",
    heroDescription2: "Protect with commitment.",
  },
  aboutUs: {
    linkCover:
      "https://drive.usercontent.google.com/download?id=1JIioPNZa-XSvNvNYzT4_f_xcjMkJi2Ak&export=download&authuser=0&confirm=t&uuid=c002c11a-ac33-4b7c-92d1-1f5408af5088&at=AC2mKKSAMQd0At7T56Wlp0Vtj3k1:1690457210903",
    downloadButton: "Download Company Profile",
    section1: "Company Profile",
    subSection1: "About Mahaguna Komando Indonesia:",
    description1:
      "Mahaguna Komando Indonesia is a licensed company engaged in providing security services based in East Kalimantan. Since the beginning of our operations on October 1st, 2018, we had expanded our coverage area in Jakarta.",
    description2:
      "<b>We have three integrated service segments</b>: security services, drone surveillance, and barge escort services. Professional performance with complete equipments can create an adaptive and efficient protection ecosystem.",
    description3:
      "Six years of consistency in the security field, Mahaguna Komando Indonesia focuses on maintaining service quality through an efficient and reliable system. We prioritize customer satisfaction by providing solutions that suit their needs.",
    section2: "Company Value",
    titlevalue1: "Professional",
    descriptionvalue1:
      "High quality standards guarantee high quality service. We train our personnels with high discipline and complete equipments.",
    titlevalue2: "Fast & Responsive",
    descriptionvalue2:
      "Through effective planning, Mahaguna Komando Indonesia performs security operations precisely and meticulously. Hence, we achieve a good coordinated security strategy.",
    titlevalue3: "Safe & Secure",
    descriptionvalue3:
      "By prioritizing integrity and credibility in service, Mahaguna Komando Indonesia conducts operations with full responsibility.",
    section3: "Vision & Mission",
    subtitle1: "Vision:",
    section3description1:
      "To become a trusted and leading professional security service provider company in Indonesia.",
    subtitle2: "Mission:",
    section3description2:
      "To focus on system efficiency in accordance with customer needs, we always improves our employees competence and professionalism in order to maintain our service quality.",
  },
  services: {
    section1: "Our Services",
    subSection1: "Security Services",
    descriptionSection1:
      "With substantial human resources, Mahaguna Komando Indonesia guarantees the security of your assets entrusted to us.",
    subSection2: "Drone Surveillance",
    descriptionSection2:
      "Drone is a cutting-edge technology. With these sophisticated machines, Mahaguna Komando Indonesia provides comprehensive land survey services for your needs.",
    subSection3: "Barge Escort Services",
    descriptionSection3:
      "Mahaguna Komando Indonesia provides river route distribution escort services, especially for coal barges. We ensure the safety of your assets from start to destination.",
  },
  career: {
    section1: "Build Your Career With Us",
    data1: "Coming Soon",
    data2: "Coming Soon",
    data3: "Coming Soon",
    buttonApply: "Apply Now",
  },
  contact: {
    data1: "Company Address",
    data2: "Phone Number",
  },
  footer: {
    navigation1: "Information",
    navigation2: "Email",
  },
};
