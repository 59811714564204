import React, { useState } from "react";
import parse from "html-react-parser";
import { Row, Col } from "react-bootstrap";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "../../contexts/translation";
import { useTransition, useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

const TentangKami = () => {
  const translation = useTranslation();
  const [data, setData] = useState(1);
  const handleClick = (selectedData) => {
    setData(selectedData);
  };

  const items = [
    <>
      <div className="content-items">
        <div className="redRoundImage">
          <img className="iconRoundImage" src="/assets/images/cardIcon-4.png" />
        </div>
        <h2 className="bold-text">{translation.aboutUs.titlevalue1}</h2>
        <p className="descriptionss">{translation.aboutUs.descriptionvalue1}</p>
      </div>
    </>,
    <>
      <div className="content-item">
        <div className="redRoundImage">
          <img className="iconRoundImage" src="/assets/images/cardIcon-5.png" />
        </div>
        <h2 className="bold-text">{translation.aboutUs.titlevalue2}</h2>
        <p className="descriptionss">{translation.aboutUs.descriptionvalue2}</p>
      </div>
    </>,
    <>
      <div className="content-item">
        <div className="redRoundImage">
          <img className="iconRoundImage" src="/assets/images/cardIcon-1.png" />
        </div>
        <h2 className="bold-text">{translation.aboutUs.titlevalue3}</h2>
        <p className="descriptionss">{translation.aboutUs.descriptionvalue3}</p>
      </div>
    </>,
  ];
  const items2 = [
    <>
      <div className="content-items">
        <h2 className="bold-text">{translation.aboutUs.subtitle1}</h2>
        <p className="descriptionss">
          {translation.aboutUs.section3description1}
        </p>
      </div>
    </>,
    <>
      <div className="content-item">
        <h2 className="bold-text">{translation.aboutUs.subtitle2}</h2>
        <p className="descriptionss">
          {translation.aboutUs.section3description2}
        </p>
      </div>
    </>,
  ];
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref4, inView4] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const transitions = useTransition(items, {
    from: { opacity: 0, transform: "translateX(-20px)" },
    enter: inView ? { opacity: 1, transform: "translateX(0px)" } : null,
    config: config.slow, // Play with different configurations to adjust the animation feel
    trail: 300, // Set the delay between each item's animation
  });
  const transitions2 = useTransition(items2, {
    from: { opacity: 0, transform: "translateX(-20px)" },
    enter: inView3 ? { opacity: 1, transform: "translateX(0px)" } : null,
    config: config.slow, // Play with different configurations to adjust the animation feel
    trail: 300, // Set the delay between each item's animation
  });
  const fadeAnimation = useSpring({
    opacity: inView2 || inView4 ? 1 : 0,
    config: config.slow,
  });

  return (
    <React.Fragment>
      <div className="containerAboutUs2">
        <animated.div style={{ ...fadeAnimation }}>
          <Row className="asyik" style={{ marginBottom: "2rem" }}>
            {/* <div className="asyik"ref={ref2}> */}
            <a
              id="oke"
              style={{ display: "none" }}
              href={translation.aboutUs.linkCover}
              target="_blank"
            />
            <Col className="wawo">
              <img
                className="imagesContentAboutUs"
                src="/assets/images/brosur.webp"
              />
              <div
                onClick={() => {
                  document.getElementById("oke").click();
                }}
                style={{ cursor: "pointer" }}
                className="buttonSeeMore1"
              >
                {translation.aboutUs.downloadButton}
              </div>
            </Col>

            <div ref={ref2} className="col marginTextImage">
              <div>
                <h2 className="aboutUs">{translation.aboutUs.section1}</h2>
                <div className="hrLineaboutUs" />
                <p className="textAboutUs">
                  <b>{translation.aboutUs.subSection1}</b>
                </p>

                <p className="textAboutUs">
                  {translation.aboutUs.description1}
                </p>
                <p className="textAboutUs">
                  {parse(translation.aboutUs.description2)}
                </p>
                <p className="textAboutUs">
                  {translation.aboutUs.description3}
                </p>
              </div>
            </div>
            {/* </div> */}
          </Row>
        </animated.div>

        <div className="hrLinebetweenSection2" />

        <div className="containerTitle col text-center">
          <h2 className="aboutUs">{translation.aboutUs.section2}</h2>
          <div className="hrLayananKami text-center" />
        </div>
        <div className="content-grid2" ref={ref}>
          {transitions((style, item) => (
            <animated.div style={style} key={item}>
              <p>{item}</p>
            </animated.div>
          ))}
        </div>
        <div style={{ marginTop: "6rem" }}>
          <div className="hrLinebetweenSection2" />
          <div className="containerTitle col text-center">
            <h2 className="aboutUs">{translation.aboutUs.section3}</h2>
            <div className="hrLayananKami text-center" />
          </div>
          <div className="content-grid3" ref={ref3}>
            {transitions2((style, item) => (
              <animated.div style={style} key={item}>
                {item}
              </animated.div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TentangKami;
