import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import useTranslation from "../../contexts/translation";
import { useTransition, useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

const Career = () => {
  const [data, setData] = useState(1);
  const translation = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.2, // Percentage of the element that must be visible to trigger the animation
  });

  const fadeAnimation = useSpring({
    opacity: inView ? 1 : 0,
    config: config.slow,
  });

  return (
    <React.Fragment>
      <div className="containerAboutUs2" ref={ref}>
        <animated.div style={{ ...fadeAnimation }}>
          <div className="containerTitle col text-center">
            <h2 className="aboutUs">{translation.career.section1}</h2>
            <div className="hrLayananKami text-center" />
          </div>
          <div className="containerCarrer">
            <div className="table">
              <div onClick={() => setData(1)} className="row">
                <div
                  className="cell cell-1 text-center"
                  style={data == 1 ? { borderColor: "#3ECE7D" } : {}}
                >
                  {data == 1 ? "-" : "+"}
                </div>
                <div
                  className="cell cell-2 "
                  style={data == 1 ? { borderColor: "#3ECE7D" } : {}}
                >
                  Design
                </div>
              </div>
            </div>
            {data == 1 ? (
              <div className="ole">
                <div className="jobTitle">
                  <div className="ColumnJob">
                    <div className="titleForJob">Product Designer</div>
                    <div className="subTitleForJob">Jakarta Pusat</div>
                  </div>
                  <div className="applyNow">
                    {translation.career.buttonApply}
                  </div>
                </div>
                <p className="descJob">{translation.career.data1}</p>
                <div className="ulala">
                  <div className="redButtonMantap">Read More</div>
                </div>
              </div>
            ) : null}
            <div className="table">
              <div onClick={() => setData(2)} className="row">
                <div
                  className="cell cell-1 text-center"
                  style={data == 2 ? { borderColor: "#3ECE7D" } : {}}
                >
                  {data == 2 ? "-" : "+"}
                </div>
                <div
                  className="cell cell-2 "
                  style={data == 2 ? { borderColor: "#3ECE7D" } : {}}
                >
                  Engineering
                </div>
              </div>
            </div>
            {data == 2 ? (
              <div className="ole">
                <div className="jobTitle">
                  <div className="ColumnJob">
                    <div className="titleForJob">Product Engineer</div>
                    <div className="subTitleForJob">Jakarta Pusat</div>
                  </div>
                  <div className="applyNow">
                    {translation.career.buttonApply}
                  </div>
                </div>
                <p className="descJob">{translation.career.data2}</p>
                <div className="ulala">
                  <div className="redButtonMantap">Read More</div>
                </div>
              </div>
            ) : null}
            <div className="table">
              <div onClick={() => setData(3)} className="row">
                <div
                  className="cell cell-1 text-center"
                  style={data == 3 ? { borderColor: "#3ECE7D" } : {}}
                >
                  {data == 3 ? "-" : "+"}
                </div>
                <div
                  className="cell cell-2 "
                  style={data == 3 ? { borderColor: "#3ECE7D" } : {}}
                >
                  Marketing
                </div>
              </div>
            </div>
            {data == 3 ? (
              <div className="ole">
                <div className="jobTitle">
                  <div className="ColumnJob">
                    <div className="titleForJob">Product Marketing</div>
                    <div className="subTitleForJob">Jakarta Pusat</div>
                  </div>
                  <div className="applyNow">
                    {translation.career.buttonApply}
                  </div>
                </div>
                <p className="descJob">{translation.career.data3}</p>
                <div className="ulala">
                  <div className="redButtonMantap">Read More</div>
                </div>
              </div>
            ) : null}
          </div>
        </animated.div>
      </div>
    </React.Fragment>
  );
};

export default Career;
