// LanguageContext.js
import React, { createContext, useState, useEffect, useContext } from "react";

const LanguageContext = createContext();

export default function LanguageContextProvider({ children }) {
  const [language, changeLanguage] = useState(() => {
    // Initialize the state with the value from localStorage or 'en' if not present
    return localStorage.getItem("language") || "en";
  });

  const setLanguage = (lang) => {
    changeLanguage(lang);
  };

  useEffect(() => {
    // Save the language to localStorage whenever it changes
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguageContext() {
  return useContext(LanguageContext);
}
