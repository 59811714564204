import React, { useState } from "react";
import "./Index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "../../contexts/translation";
import { useTransition, useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

const Contact = () => {
  const translation = useTranslation();
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref4, inView4] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });
  const [ref5, inView5] = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.5, // Percentage of the element that must be visible to trigger the animation
  });

  const fadeAnimation = useSpring({
    opacity: inView || inView2 || inView3 || inView4 ? 1 : 0,
    config: config.default,
  });
  const fadeAnimation2 = useSpring({
    opacity: inView4 || inView5 ? 1 : 0,
    config: config.default,
  });

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const sendEmail = () => {
    const subject = encodeURIComponent(
      `Inquiry from ${name} - Mahaguna Security Service Website`
    );
    const body = encodeURIComponent(`Halo,

Saya ingin menghubungi Anda mengenai hal berikut:

Nama: ${name}
Email: ${email}

Pesan:
${message}

Tolong berikan tanggapan secepatnya untuk membahas lebih lanjut. Terima kasih atas waktu dan perhatiannya.

Salam,
${name}`);
    const mailtoLink = `mailto:richard.hee@mkisecurity.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };
  return (
    <React.Fragment>
      <div className="containerAboutUs2">
        <animated.div style={{ ...fadeAnimation }}>
          <div className="grid-container33">
            <div ref={ref} className="grid-item12">
              <div style={{ marginTop: "3rem" }} className="containerContact">
                <img
                  src="/assets/images/location.svg"
                  className="imageContact"
                />
                <h2 style={{ marginTop: "3rem", color: "#FFFFFF" }}>
                  {translation.contact.data1}
                </h2>
                <div className="hrPerusahaan" />
                <p
                  className="text-center teks-Telepon"
                  style={{ color: "#FFFFFF" }}
                >
                  Commodity Square Lt. 1 Jl. Gatot Subroto No.35-36, <br />
                  RT.6/RW.3, Kuningan, Kuningan Tim., <br />
                  Kecamatan Setiabudi, Kota Jakarta Selatan, <br />
                  Daerah Khusus Ibukota Jakarta 12950
                </p>
              </div>
            </div>
            <div ref={ref2} className="grid-item12">
              <div className="containerContact">
                <img
                  src="/assets/images/email-icon.svg"
                  className="imageContact"
                />
                <h2 style={{ marginTop: "2rem", color: "#FFFFFF" }}>E-mail</h2>
                <div className="hrPerusahaan" />
                <h2 className="text-center" style={{ color: "#FFFFFF" }}>
                  <a
                    href="mailto:info@mkisecurity.com"
                    style={{ color: "#FFFFFF" }}
                  >
                    info@mkisecurity.com
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </animated.div>
        <animated.div style={{ ...fadeAnimation2 }}>
          <div style={{ marginTop: "3rem" }} className="rowAha">
            <form ref={ref4} className="flexAha">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className="inputText">Your Name*</p>
                <input
                  className="oleInput"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  required
                ></input>
                <p className="inputText">Your email address</p>
                <input
                  className="oleInput"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                ></input>
                <p className="inputText">Message</p>
                <textarea
                  // cols="40"
                  // rows="5"
                  className="oleInput2"
                  type="text"
                  value={message}
                  onChange={handleMessageChange}
                  required
                ></textarea>
                <button
                  type="button"
                  style={{ width: "150px", marginTop: "2rem" }}
                  className="applyNow"
                  onClick={sendEmail}
                >
                  Send Message
                </button>
              </div>
            </form>

            <div ref={ref5} className="flexAha">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.898293172417!2d106.8243046!3d-6.2341002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f32ddc4a01a5%3A0x44b992e384df44b1!2sCommodity%20Square!5e0!3m2!1sid!2sid!4v1691473519538!5m2!1sid!2sid"
                width="600"
                height="550"
                style={{ border: "0", borderRadius: "14px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </animated.div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
